<template>
  <div class="church-list template-1">
    <header class="page-header container">
      <h1>{{ translations.tcCampMemberChurches }}</h1>
    </header>
    <!-- / Page Header -->

    <camp-selector @camp_changed="page_load()" :tooltip="translations.tcTooltip" :i18n="translations.components"></camp-selector>
    <!-- / Camp Selector -->

    <data-table
      :fields="fields"
      :listType="list_type"
      :items="memberChurches"
      :toggleMe="true"
      :addItems="addItems"
      :csvUrl="csvUrl"
      :handleViewAction="false"
      :i18n="translations.components"
      @handleEditClick="handleEditClick"
      @handleViewClick="handleViewClick"
    ></data-table>
    <!-- / Data Tables -->

    <footer class="page-footer"></footer>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import CampSelect from '@/components/CampSelect.vue'
import { countryBreadcrumbMixin } from '@/mixins/breadcrumbMixinFactory'
import DataTable from '@/components/DataTableMemberChurches.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'camp-member-churches',
  mixins: [translationMixin, countryBreadcrumbMixin],
  data() {
    return {
      translations: {},
      list_type: 'camp_member_churches',
      fields: [
        { key: 'name', label: 'Name', sortable: true },
        { key: 'church_name', label: 'Church Name', sortable: false },
        { key: 'actions', label: 'Actions', sortable: false },
      ],
      hiddenItems: {
        display: false,
      },
      addItems: {
        display: false,
      },
    }
  },
  methods: {
    ...mapActions({
      getMemberChurches: 'campModule/getMemberChurches',
      getCampMembersChurchesUrl: 'churchMinistry/getCampMembersChurchesUrl',
      loadChurches: 'churchMinistry/loadChurches',
      setLoadingStatus: 'menu/setLoadingStatus',
      setSelectedIndividualKey: 'user/setSelectedIndividualKey',
    }),
    async page_load() {
      try {
        this.setLoadingStatus(true)
        await Promise.all([
          this.getMemberChurches(this.userOrgKey),
          this.getCampMembersChurchesUrl(),
          this.getViewTranslations(),
          this.getComponentTranslations('camp-select', 'data-table-member-churches', 'security-banner')
        ]).then((results) => {
          const componentTranslations = results[3]
          this.$set(this.translations, 'components', componentTranslations)
          this.loadTranslations()
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    addPresenter() {
      this.$router.push('/')
    },
    handleEditClick(data) {
      this.setSelectedIndividualKey(data.item.ind_key)
      this.$router.push({ name: 'individual-details-edit' })
    },
    handleViewClick(data) {
      this.setSelectedIndividualKey(data.item.ind_key)
      this.$router.push({ name: 'member-profile' })
    },
    loadTranslations() {
      this.addItems.text = this.translations.tcAddVisit

      const { tcActions, tcChurchName, tcName} = this.translations.components['data-table-member-churches']
      this.fields[0].label = tcName
      this.fields[1].label = tcChurchName
      this.fields[2].label = tcActions
    },
  },
  computed: {
    ...mapGetters({
      csvUrl: 'churchMinistry/campMembersChurchesUrl',
      memberChurches: 'campModule/campMemberChurches',
      prefCulture: 'user/userPreferredCulture',
      userOrgKey: 'user/userCampKey',
    }),
  },
  components: {
    campSelector: CampSelect,
    dataTable: DataTable,
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/views/ChurchList.scss';
</style>
